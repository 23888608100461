import { Row, Col, Card, Button } from "antd";
import React from "react";
import Footer from "./Footer";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
const baseUrl = process.env.REACT_APP_BASE_URL;
const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(stripeKey);
const ProductSubscription = (props) => {
  const handleClick = async (value, mode) => {
    console.log("dd");
    const id = {
      price_id: value,
      format: "json",
      mode,
    };
    const response = await axios.post(`${baseUrl}create_checkout_session`, id, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.id) {
      const stripe = await stripePromise;
      // eslint-disable-next-line
      const { error } = await stripe
        .redirectToCheckout({
          sessionId: response.data.id,
        })
        .then(function (result) {
          console.log(result.error.message);
        });
    }
  };
  return (
    <>
      <Row
        gutter={[16, 16]}
        justify="center"
        style={{
          // width: "70%",
          margin: "auto",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
      >
        {/* <Row  gutter={[16, 16]}> */}
        <Col span={16}>
          <h1>Product Subscription</h1>
          <Card style={{ boxShadow: "5px 7px 20px -1px gainsboro" }}>
            <Row>
              {/* <Col span={4}>
                <img
                  alt="watch4"
                  src="https://rukminim1.flixcart.com/image/416/416/jeek8sw0/smartwatch/d/w/y/cz1-aberdeen-original-imaf3y9fz6wrpnkr.jpeg?q=70"
                  height="100px"
                  width="100px"
                />
              </Col> */}
              <Col span={20}>
                Monthly Subscription <br />
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Unlimited
                access to data <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pre-selected
                investors view <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All Reports{" "}
                <br />
                <Button
                  type="primary"
                  style={{ float: "right", marginTop: "20px" }}
                  // onClick={() => props.history.push("/stripe")}
                  onClick={() =>
                    handleClick(
                      "price_1MnhDtKNWNPlRrHR9fIt1M7b",
                      "subscription"
                    )
                  }
                >
                  19.99$ per month
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={16}>
          <Card style={{ boxShadow: "5px 7px 20px -1px gainsboro" }}>
            <Row>
              <Col span={20}>
                <bold>Yearly Subscription </bold>
                <br />
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Unlimited
                access to data <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pre-selected
                investors view <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All Reports{" "}
                <br />
                <Button
                  type="primary"
                  style={{ float: "right", marginTop: "20px" }}
                  // onClick={() => props.history.push("/stripe")}
                  onClick={() =>
                    handleClick("price_1IrRnOKNWNPlRrHRNyJUlBTI", "payment")
                  }
                >
                  200$ per year(20% savings)
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        {/* <Col span={16}>
          <Card style={{ boxShadow: "5px 7px 20px -1px gainsboro" }}>
            <Row>
              <Col span={4}>
                {" "}
                <img
                  alt="watch2"
                  src="https://rukminim1.flixcart.com/image/416/416/jeek8sw0/smartwatch/d/w/y/cz1-aberdeen-original-imaf3y9fz6wrpnkr.jpeg?q=70"
                  height="100px"
                  width="100px"
                />
              </Col>
              <Col span={20}>
                General Sales Package 1 Smartwatch, 1 User Manual, 1 Charging
                Cable Model Number CZ1 Model Name CZ1 phone Dial Color Black
                Dial Shape Circle Strap Color Black Strap Material Silicone Size
                Free Size Touchscreen Yes Water Resistant No Usage Watchphone
                Dial Material Alloy Ideal For Men & Women
                <Button
                  type="primary"
                  style={{ float: "right", marginTop: "20px" }}
                  // onClick={() => props.history.push("/stripe")}
                  onClick={() => handleClick("price_1IrRnOKNWNPlRrHRNyJUlBTI")}
                >
                  Subscribe plan
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={16}>
          <Card style={{ boxShadow: "5px 7px 20px -1px gainsboro" }}>
            <Row>
              <Col span={4}>
                {" "}
                <img
                  alt="watch1"
                  src="https://rukminim1.flixcart.com/image/416/416/jeek8sw0/smartwatch/d/w/y/cz1-aberdeen-original-imaf3y9fz6wrpnkr.jpeg?q=70"
                  height="100px"
                  width="100px"
                />
              </Col>
              <Col span={20}>
                General Sales Package 1 Smartwatch, 1 User Manual, 1 Charging
                Cable Model Number CZ1 Model Name CZ1 phone Dial Color Black
                Dial Shape Circle Strap Color Black Strap Material Silicone Size
                Free Size Touchscreen Yes Water Resistant No Usage Watchphone
                Dial Material Alloy Ideal For Men & Women
                <Button
                  type="primary"
                  style={{ float: "right", marginTop: "20px" }}
                  // onClick={() => props.history.push("/stripe")}
                  onClick={() => handleClick("price_1IrRnOKNWNPlRrHRNyJUlBTI")}
                >
                  Subscribe plan
                </Button>
              </Col>
            </Row>
          </Card>
        </Col> */}
        {/* </Row> */}
      </Row>
      <Footer />{" "}
    </>
  );
};

export default ProductSubscription;
